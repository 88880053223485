// node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss
// An example:
// $brand-primary: #00ceff;

.navbar-brand {
  padding: 5px;
}

.alert-danger {
  h3 {
    margin-top: 0;
    font-size: 16px;
    font-weight: 700;
  }

  ul, .list-group {
    margin-left: 0;
    margin-bottom: 0;
  }
}
