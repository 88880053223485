.home {
  table {
    width: 100%;
  }

  table > thead > tr > th, table > tbody > tr > td {
    padding: 10px 10px 10px 0;
    font-size: 1.4rem;
    vertical-align: middle;
  }

  tr.list-group-item .header span {
    font-size: 1.68rem;
    font-weight: 500;
    display: block;
  }

  tr.list-group-item, thead tr {
    display: table-row;
    border: 0;
    border-radius: 0;
    border-bottom: solid 1px #ddd;
    margin: 0 0 1px 0;

    .timestamp {
      font-weight: normal;
    }

    .status {
      min-height: 25px;
      vertical-align: top;

      span {
        margin: 0 !important;

        > span {
          font-weight: 500;
          letter-spacing: 0.3px;
        }
      }
    }

    .action-container {
      text-align: center;

      button {
        padding: 2px 15px;
        height: 100%;
        float: none !important;
        margin: 0;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .status {
      overflow: hidden;
    }

    .action-container {
      button {
        padding: 6px 6px;
      }
    }
  }
}
