.valuesView {
  margin-bottom: 60px;

  .table {
    hyphens: auto;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    > tbody {
      > tr {
        border-top: 1px solid #ddd;

        &:first-of-type {
          border-top: none;
        }

        > td {
          border-top: none;
          border-bottom: 1px solid #ddd;

          .label {
            white-space: unset;
            margin-bottom: 6px;
            display: inline-block;
            text-align: left;
            padding: 5px 6.75px;
            font-size: 1.125rem;
            line-height: normal;
            letter-spacing: 0.6px;
          }
        }
      }
    }
  }
}
